const strings = Object.freeze({
  URL_TERMS: 'https://movring-assets.s3.ap-northeast-1.amazonaws.com/movring_%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84.pdf',
  URL_MANUAL: 'https://movring-assets.s3.ap-northeast-1.amazonaws.com/movring_%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB.pdf',
  ERROR_TITLE_PROJECT: 'プロジェクト詳細情報取得エラー',
  ERROR_DESCRIPTION_PROJECT: 'プロジェクト詳細情報の取得に失敗しました。しばらく待ってからやり直してください。',
  ERROR_DESCRIPTION_UPLOAD_FILE: 'ファイルアップロードに失敗しました。しばらく待ってからやり直してください。',
  ERROR_DESCRIPTION_GET_SUBMIT_FILE: '入稿ファイルの取得に失敗しました。しばらく待ってからやり直してください。',
  ERROR_DESCRIPTION_SUBMIT: '入稿に失敗しました。しばらく待ってからやり直してください。',
  ERROR_DESCRIPTION_DOWNLOAD_ZIP: 'ファイルダウンロードに失敗しました。しばらく待ってからやり直してください。',
  ERROR_DESCRIPTION_DOWNLOAD_FILE: 'ファイルダウンロードに失敗しました。しばらく待ってからやり直してください。',
  ERROR_DESCRIPTION_DOWNLOAD_TEMPLATE_DEFAULT: 'テンプレート指示書ダウンロードに失敗しました。しばらく待ってからやり直してください。',
  ERROR_DESCRIPTION_DOWNLOAD_TEMPLATE_404: '入力されたテンプレートIDは存在していません。',
  ERROR_DESCRIPTION_GET_USER: 'ユーザー情報の取得に失敗しました。しばらく待ってからやり直してください。',
  SUBMIT_DESCRIPTION_1: '以下のファイルを入稿します。',
  SUBMIT_DESCRIPTION_2: '入稿データ（素材・指示書）が揃っているか確認し、問題なければ「入稿」ボタンをクリックしてください。入稿すると、設定した通知先へ入稿完了メールを送信してお知らせします。',
  SUCCESS_SUBMIT_MESSAGE: '入稿完了しました。',
  INPUT_PLACEHOLDER: '必須項目*',
  INCORRECT_SIGNIN_PAGE: '正しいログインページから再ログインを行ってください。',
  confirmDialog: {
    CANCEL: 'キャンセル',
    OK: 'OK',
  },
  signIn: {
    ID_SIGNIN: 'ログインIDまたはメールアドレス',
    PASSWORD_SIGNIN: 'パスワード',
    FORGET_PASSWORD: 'パスワードを忘れた時',
    SUBMIT_SIGNIN: 'ログイン',
    validationError: {
      REQUIRED_ID: 'ログインIDが入力されていません。',
      REQUIRED_PASSWORD: 'パスワードが入力されていません。',
      REQUIRED_RECAPTCHA: 'チェックがされていません。',
      COGNITO: 'ログインIDまたはパスワードが正しくありません',
    },
    firstSignIn: {
      TERMS_OF_USE: '利用規約を確認する',
      POPOVER_MESSAGE: 'ログインには利用規約の確認が必須となります。',
    },
  },
  createProjectPage: {
    TITLE: 'プロジェクト新規作成',
    LABEL_PROJECT_NAME: 'プロジェクト名',
    LABEL_ACCOUNT_NAME: 'アカウント名',
    BUTTON_NOTIFICATION: '通知先設定',
    BUTTON_NOTIFICATION_TOPPAN: '凸版通知先設定',
    MESSAGE_MOVE_TO_PJ: '※ 指示書・素材データの入稿画面へ遷移します',
    BUTTON_SAVE: '保存',
    validationError: {
      REQUIRED: '必須項目が入力されていません。',
    },
    notificationDialog: {
      MESSAGE: '担当アカウント以外に、通知先の設定が可能です。（最大20件）\n通知先を設定しない場合は、そのまま【保存】をクリックしてください。',
      TEXT_LABEL_MAIL: 'メールアドレスを入力',
      BUTTON_CANCEL: 'キャンセル',
      BUTTON_SAVE: '保存',
      ERROR_LIMIT: '登録可能な上限を超えています。',
      ERROR_FORMAT: 'メールアドレスの形式に誤りがあります。',
    },
    completionMessage: {
      SUCCESS: 'プロジェクトを作成しました。',
      ERROR: 'プロジェクトの作成に失敗しました。',
      ERROR_GET_USER: 'ユーザー情報の取得に失敗しました。しばらく待ってからやり直してください。',
      ERROR_GET_CUSTOMERS: '企業アカウント情報の取得に失敗しました。しばらく待ってからやり直してください。',
    },
  },
  projects: {
    status: {
      BEFORE_SUBMISSION: '入稿前',
      PRODUCTION: '制作中',
      CHECKING: '確認中',
      COMPLETION: '完了',
    },
    menu: {
      LABEL: 'メニュー',
      DIVERSION: '流用',
      DELETE: '削除',
    },
    completionMessage: {
      diversion: {
        SUCCESS: 'プロジェクトを作成しました。',
        ERROR: 'プロジェクトの作成に失敗しました。',
      },
      delete: {
        SUCCESS: 'プロジェクトを削除しました。',
        ERROR: 'プロジェクトの削除に失敗しました。',
      },
      getProjects: {
        ERROR: 'プロジェクト情報の取得に失敗しました。しばらく待ってからやり直してください。',
      },
    },
    deleteDialog: {
      TITLE: 'プロジェクト削除',
      DESCRIPTION: '削除してもよろしいですか？',
    },
  },
  projectDetail: {
    fileDeleteDialog: {
      DIALOG_TITLE: 'ファイル削除',
      TEXT: '削除してもよろしいですか？',
    },
    completionMessage: {
      saveConfirmationUrl: {
        SUCCESS: '確認用動画URLを保存しました。',
        ERROR: '確認用動画URLの保存に失敗しました。しばらく待ってからやり直してください。',
      },
      copyConfirmationUrl: {
        SUCCESS: 'コピーしました。',
        ERROR: 'コピーに失敗しました。',
      },
      uploadFile: {
        FILE_INVALID_TYPE: '取扱対象外のファイルです。',
        FILE_TOO_LARGE: 'ファイルサイズが5GBを超えています。ファイルを分割してアップロードしてください。',
        FILE_TOO_SMALL: 'ファイルサイズが0Bです。',
        DEFAULT: 'このファイルはアップロードできません。',
      },
      getProject: {
        ERROR: 'プロジェクト詳細情報の取得に失敗しました。しばらく待ってからやり直してください。',
      },
      getUser: {
        ERROR: 'ユーザー情報の取得に失敗しました。しばらく待ってからやり直してください。',
      },
      deleteFile: {
        ERROR: 'ファイル削除に失敗しました。しばらく待ってからやり直してください。',
      },
      downloadZip: {
        ERROR_429: '合計容量が大きすぎます。個別でファイルをダウンロードしてください。',
        ERROR_424: 'ウイルスチェック中のファイルがあるため、まとめてダウンロードできません。個別にダウンロードしてください。',
        ERROR_DEFAULT: 'ファイルダウンロードに失敗しました。しばらく待ってからやり直してください。もしくは個別でファイルをダウンロードしてください。',
      },
    },
    fileDeleteButtonField: {
      delete: 'ファイルを削除しました。',
    },
    projectInfo: {
      SUBMITTED_AT: '入稿日',
      CONFIRMATION_REQUESTED_AT: '確認動画アップロード日',
      MESSAGE_NOTE: 'メモ欄',
      BUTTON_SEND: '保存',
      PROJECT_ID: 'プロジェクトID',
      COPIED_ID: '流用元 ID',
      COPIED_PROJECT: '流用元プロジェクト',
      PROJECT_NAME: 'プロジェクト名',
      BUTTON_SAVE: '保存',
      LAST_UPDATE: '最終更新日:',
      SUBMITTED_AT_POPOVER_MESSAGE: '・最新の入稿日時が反映されます。',
      CONFIRMATION_REQUESTED_AT_POPOVER_MESSAGE: '・最新の確認動画がアップロードされた日時が反映されます。',
      MESSAGE_NOTE_POPOVER_MESSAGE: '・プロジェクトに関するメモを残すことが可能です。\n・メンバー全員が閲覧・編集（削除）可能です。',
      SUBMISSION_POPOVER_MESSAGE: 'ファイル（素材・指示書）が揃った時点で、【入稿確定】してください。\n【入稿確定】と同時に制作依頼通知が送信されます。\n動画の修正指示を行う場合も同様の手順を踏んでください。\n誤って【入稿確定】を行ってしまった場合は、弊社担当にお知らせください。',
      notifications: {
        BUTTON_CUSTOMER: '通知先設定',
        BUTTON_TOPPAN: '凸版通知先設定',
        UPDATE_SUCCESS: '通知先を更新しました。',
        UPDATE_ERROR: '通知先の更新に失敗しました。',
        UPDATE_SUCCESS_TOPPAN: '凸版通知先を更新しました。',
        UPDATE_ERROR_TOPPAN: '凸版通知先の更新に失敗しました。',
      },
      UPDATE_SUCCESS: 'プロジェクト情報を更新しました。',
      UPDATE_ERROR: 'プロジェクト情報の更新に失敗しました。',
      EDIT_NOTE_SUCCES: 'メモ欄を更新しました。',
      EDIT_NOTE_ERROR: 'メモ欄の更新に失敗しました。',
      ERROR_CONFLICT_TITLE: 'エラー',
      ERROR_CONFLICT_DESCRIPTION: '編集作業中に他のユーザーによってプロジェクトが更新されました',
      ERROR_CONFLICT_RELOAD: 'プロジェクトを再読み込みする',
      ERROR_REQUIRED: '必須項目が入力されていません。',
    },
    videoConfirmation: {
      POPOVER_MESSAGE: 'コピー',
      BUTTON_SAVE: '保存',
      validation: {
        URL_FORMAT: '正しい形式のURLを入力してください。',
      },
      ACCEPT: '承認',
      CONFIRMATION_VIDEO_ACCEPT: '確認用動画承認',
      CONFIRMATION_VIDEO_SEND_BACK: '確認用動画差戻',
      FIX_COMMENT: '修正コメント',
      SEND_FIX_INSTRUCTIONS: '修正指示を送信',
      SEND_BACK_TO_TGC: 'TGCへ差戻',
      COMMENT: 'コメント（申し送り等）',
      DEFAULT_COMMENT_SEND_TO_CUSTOMER: '確認用動画が完成致しました。\nプロジェクトURLからご確認よろしくお願いします。',
      SEND_TO_CUSTOMER: '得意先へ送信',
      SEND_BACK_COMMENT: '差戻コメント（修正箇所等）',
      CUSTOMER_ACCEPT_SUCCESS: '確認用動画を承認しました。',
      CUSTOMER_ACCEPT_ERROR: '承認に失敗しました。しばらく待ってからやり直してください。',
      CUSTOMER_ACCEPT_ALERT_BODY: '【承認】いただくと納品用動画の作成を開始いたします。',
      CUSTOMER_SEND_BACK_TEXT: '修正内容を以下にご記載ください。\n追加で必要な素材/指示書があれば、再度入稿画面にてアップロードをお願いします。\n【送信】した後、入稿画面に遷移します。',
      CUSTOMER_SEND_BACK_SUB_TEXT: '※【送信】後は確認動画の閲覧ができなくなります。修正内容はまとめてご記載ください。\n※ 修正は軽微な修正1回のみとなります。\n※ 大幅な修正、または2回目以降の修正は別途費用が発生する場合がございます。',
      CUSTOMER_ACCEPTED_VIDEO_SEND_BACK: '得意先承認済動画差戻',
      CUSTOMER_SEND_BACK_SUCCESS: '修正指示を送信しました。',
      CUSTOMER_SEND_BACK_ERROR: '送信に失敗しました。しばらく待ってからやり直してください。',
      SALES_ACCEPT_DIALOG_TEXT: '得意先ご担当者様へ「動画確認のご依頼」メールを送信いたします。\nメールに記載するコメント（申し送り等）を下記にて編集することができます。',
      SALES_SEND_BACK_DIALOG_TEXT: 'movring制作チーム（TGC）に差戻を実施します。\n修正が必要な点を以下にご記載ください。',
      SALES_SEND_BACK_WARNING_ALERT_BODY: 'movringの修正対応は1回分がプランに含まれており、\n2回目以降の修正は追加費用が発生する場合がございます。\n「差戻」も1回に含まれます。「差戻」を実施しますか？',
      SALES_ACCEPT_SUCCESS: '得意先へ確認依頼を送信しました。',
      SALES_ACCEPT_ERROR: '送信に失敗しました。しばらく待ってからやり直してください。',
      SALES_SEND_BACK_SUCCESS: '確認用動画を差戻しました。',
      SALES_SEND_BACK_ERROR: '差戻に失敗しました。しばらく待ってからやり直してください。',
      MOVRING_AND_ADMIN_SEND_BACK_ALERT_BODY: '得意先承認済みですが、差戻しますか？',
      MOVRING_AND_ADMIN_SEND_BACK_SUCCESS: '確認用動画を差戻しました。',
      MOVRING_AND_ADMIN_SEND_BACK_ERROR: '差戻に失敗しました。しばらく待ってからやり直してください。',
    },
  },
  customerSignUp: {
    HEADER_TITLE: '企業アカウント新規登録',
    TITLE: {
      CUSTOMER_DATA: '企業情報',
      TOPPAN_DATA: '凸版担当情報',
    },
    TEXT_LABEL: {
      CUSTOMER_NAME: '企業名',
      CUSTOMER_CD: '企業コード',
      ACCOUNT_NAME: '担当者名',
      EMAIL: '担当者メールアドレス',
      SALES_EMAIL: '営業担当メールアドレス',
      MOVRING_EMAIL: 'movring担当メールアドレス',
      TGC_EMAIL: 'TGC担当メールアドレス',
    },
    BUTTON: {
      SIGN_UP: '登録',
    },
    validationError: {
      REQUIRED: '必須項目が入力されていません。',
      EMAIL_FORMAT: 'メールアドレスの形式に誤りがあります。',
      CUSTOMER_CODE_FORMAT: '半角英数字6桁で入力してください。',
    },
    customerForm: {
      POPOVER_MESSAGE_CUSTOMER_NAME: '正式名称を入力してください。',
      POPOVER_MESSAGE_CUSTOMER_CD: 'BITの得意先CDを入力してください。',
    },
    completionMessage: {
      ERROR_PERMISSION: '権限がありません。',
      ERROR_GET_USER: 'ユーザー情報の取得に失敗しました。しばらく待ってからやり直してください。',
      ERROR_ADD_CUSTOMER: '企業アカウントの新規登録に失敗しました。しばらく待ってからやり直してください。',
      ERROR_ADD_CUSTOMER_404: '凸版アカウントとして登録されていないメールアドレスが含まれています。',
      ERROR_DUPLICATE: 'すでに登録されているメールアドレスです。',
    },
  },
  editCustomer: {
    HEADER_TITLE: '企業アカウント編集',
    TITLE: {
      CUSTOMER_DATA: '企業情報',
      TOPPAN_DATA: '凸版担当情報',
    },
    TEXT_LABEL: {
      CUSTOMER_NAME: '企業名',
      CUSTOMER_CD: '企業コード',
      DEPARTMENT: '部署',
      MANAGER: '責任者名',
      ACCOUNT_NAME: '担当者名',
      EMAIL: '担当者メールアドレス',
      SALES_EMAIL: '営業担当メールアドレス',
      MOVRING_EMAIL: 'movring担当メールアドレス',
      TGC_EMAIL: 'TGC担当メールアドレス',
    },
    BUTTON: {
      SAVE: '保存',
      DISABLE: 'アカウント無効化',
      RESEND_PASSWORD: '仮パスワード再送',
    },
    validationError: {
      REQUIRED: '必須項目が入力されていません。',
      EMAIL_FORMAT: 'メールアドレスの形式に誤りがあります。',
      CUSTOMER_CODE_FORMAT: '半角英数字6桁で入力してください。',
    },
    customerForm: {
      POPOVER_MESSAGE_CUSTOMER_NAME: '正式名称を入力してください。',
      POPOVER_MESSAGE_CUSTOMER_CD: 'BITの得意先CDを入力してください。',
    },
    completionMessage: {
      SUCCESS: '企業アカウント情報を編集しました。',
      ERROR_PERMISSION: '権限がありません。',
      ERROR_GET_USER: 'ユーザー情報の取得に失敗しました。しばらく待ってからやり直してください。',
      ERROR_GET_CUSTOMER: '企業アカウント情報の取得に失敗しました。しばらく待ってからやり直してください。',
      ERROR_EDIT_CUSTOMER: '企業アカウントの編集に失敗しました。しばらく待ってからやり直してください。',
      ERROR_EDIT_CUSTOMER_404: '凸版アカウントとして登録されていないメールアドレスが含まれています。',
    },
    disableAccount: {
      completionMessage: {
        SUCCESS: 'アカウントを無効化しました。',
        ERROR: 'アカウントの無効化に失敗しました。',
      },
      dialog: {
        TITLE: 'アカウント無効化',
        DESCRIPTION: '無効化してもよろしいですか？',
      },
    },
    resendTmpPassword: {
      completionMessage: {
        SUCCESS: '仮パスワードを再送信しました。',
        ERROR: '仮パスワードを再送信に失敗しました。',
      },
      dialog: {
        TITLE: '仮パスワード再送',
        DESCRIPTION: '仮パスワードを再送してもよろしいですか？',
      },
    },
  },
  editSupplier: {
    HEADER_TITLE: '凸版アカウント編集',
    RADIO: {
      TITLE: '権限',
      SALES: '営業',
      MOVRING: 'movring担当',
      ADMIN: '管理者',
    },
    TEXT_LABEL: {
      COMPANY: '企業名',
      BUSINESS_UNIT: '事業部',
      DEPARTMENT: '部署',
      NAME: '担当者名',
      EMAIL: 'メールアドレス',
    },
    BUTTON: {
      SAVE: '保存',
      DISABLE: 'アカウント無効化',
      RESEND_PASSWORD: '仮パスワード再送',
    },
    validationError: {
      REQUIRED: '必須項目が入力されていません。',
    },
    completionMessage: {
      SUCCESS: '凸版アカウント情報を編集しました。',
      ERROR_PERMISSION: '権限がありません。',
      ERROR_GET_USER: 'ユーザー情報の取得に失敗しました。しばらく待ってからやり直してください。',
      ERROR_GET_SUPPLIER: '凸版アカウント情報の取得に失敗しました。しばらく待ってからやり直してください。',
      ERROR_EDIT_SUPPLIER: '凸版アカウントの編集に失敗しました。しばらく待ってからやり直してください。',
      ERROR_EDIT_SUPPLIER_424: '権限を変更できません。',
    },
    disableAccount: {
      completionMessage: {
        SUCCESS: 'アカウントを無効化しました。',
        ERROR: 'アカウントの無効化に失敗しました。',
      },
      dialog: {
        TITLE: 'アカウント無効化',
        DESCRIPTION: '無効化してもよろしいですか？',
      },
    },
    resendTmpPassword: {
      completionMessage: {
        SUCCESS: '仮パスワードを再送信しました。',
        ERROR: '仮パスワードを再送信に失敗しました。',
      },
      dialog: {
        TITLE: '仮パスワード再送',
        DESCRIPTION: '仮パスワードを再送してもよろしいですか？',
      },
    },
  },
  tentativeSignUpComplete: {
    TENTATIVE_SIGN_UP_COMPLETE_TITLE: 'アカウントの仮登録が完了しました。',
    TENTATIVE_SIGN_UP_COMPLETE_1: '宛に、アカウント本登録URLが記載されたメールを送信しました。',
    TENTATIVE_SIGN_UP_COMPLETE_2: 'メール内のURLから本登録作業を実施してください。',
    MOVE_TOP_BUTTON_TEXT: 'TOPへ',
    CUSTOMER: '企業の担当者様',
    TOPPAN: '担当者',
  },
  editAccountData: {
    MANAGER_MESSAGE: '・案件の責任者が、担当者と別の場合はご入力ください。',
    TEXT_LABEL: {
      COMPANY: '企業名',
      DEPARTMENT: '部署',
      RESPONSIBLE_PARTY: '責任者名',
      PERSON_IN_CHARGE: '担当者名',
      EMAIL: '担当者メールアドレス',
      LOGIN_ID: 'ログインID',
    },
    BUTTON: {
      SAVE: '登録',
    },
    validationError: {
      REQUIRED: '必須項目が入力されていません。',
      LOGIN_ID_ERROR: '半角英数字と\'_\'(アンダーバー)のみ使用できます。',
    },
    completionMessage: {
      PERMISSION_ERROR: '権限がありません。',
      SUCCESS_EDIT_USER: 'アカウント情報を変更しました。',
      ERROR_GET_USER: 'ユーザー情報の取得に失敗しました。しばらく待ってからやり直してください。',
      ERROR_EDIT_USER: 'アカウント情報の変更に失敗しました。しばらく待ってからやり直してください。',
      ERROR_DUPLICATE: 'すでに登録されているログインIDです',
    },
    popover: {
      MESSAGE: '・メールアドレス以外にも自由にログインIDを設定していただけます。\n・半角英数字と\'_\'(アンダーバー)のみ使用できます。',
    },
  },
  customers: {
    HEADER_TITLE: '企業アカウント一覧',
    ERROR_GET_CUSTOMERS: '企業アカウント情報の取得に失敗しました。しばらく待ってからやり直してください。',
    errorUserInfo: {
      ERROR_GET_USER: 'ユーザー情報の取得に失敗しました。しばらく待ってからやり直してください。',
      ERROR_PERMISSION: '権限がありません。',
    },
    tableHeader: {
      COMPANY: '企業名',
      DEPARTMENT: '部署',
      NAME: '担当者',
      SALES: '凸版営業',
      MOVRING: 'movring担当',
      TGC: 'TGC担当',
    },
  },
  suppliers: {
    HEADER_TITLE: '凸版アカウント一覧',
    ERROR_GET_SUPPLIERS: '凸版アカウント情報の取得に失敗しました。しばらく待ってからやり直してください。',
    errorUserInfo: {
      ERROR_GET_USER: 'ユーザー情報の取得に失敗しました。しばらく待ってからやり直してください。',
      ERROR_PERMISSION: '権限がありません。',
    },
    tableHeader: {
      COMPANY: '企業名',
      BUSINESS_UNIT: '事業部',
      DEPARTMENT: '部署',
      NAME: '担当者',
      ROLE: '権限',
      CREATED_AT: '登録日時',
    },
    role: {
      SALES: '営業',
      MOVRING: 'movring担当',
      ADMIN: 'TGC',
    },
  },
  toppanSignUp: {
    TITLE: '凸版アカウント新規登録',
    RADIO: {
      TITLE: '権限',
      SALES: '営業',
      MOVRING: 'movring担当',
      ADMIN: '管理者',
    },
    TEXT_LABEL: {
      COMPANY: '企業名',
      BUSINESS_UNIT: '事業部',
      DEPARTMENT: '部署',
      NAME: '担当者名',
      EMAIL: 'メールアドレス',
    },
    BUTTON: {
      SIGN_UP: '登録',
    },
    validationError: {
      REQUIRED_ROLE: '権限が選択されていません。',
      REQUIRED: '必須項目が入力されていません。',
      EMAIL_FORMAT: 'メールアドレスの形式に誤りがあります。',
    },
    completionMessage: {
      ERROR_PERMISSION: '権限がありません。',
      ERROR_GET_USER: 'ユーザー情報の取得に失敗しました。しばらく待ってからやり直してください。',
      ERROR_ADD_SUPPLIER: '凸版アカウントの新規登録に失敗しました。しばらく待ってからやり直してください。',
      ERROR_DUPLICATE: 'すでに登録されているメールアドレスです。',
    },
    popover: {
      POPOVER_MESSAGE: '自社の情報（凸版印刷株式会社 / 株式会社トッパングラフィックコミュニケーションズ等）を入力してください。',
    },
  },
  changeTemporaryPassword: {
    validationError: {
      REQUIRED: '必須項目が入力されていません。',
      PASSWORD_FORMAT: 'パスワードが正しくありません。数字・英字大文字・英字小文字を使用して8文字以上で登録してください。',
      CONFIRM_ERROR_NEW_PASSWORD: '新しいパスワードの入力内容が異なります。再度新しいパスワードを入力してください。',
      CONFIRM_ERROR_CONFIRM_PASSWORD: '新しいパスワード（確認）の入力内容が異なります。再度新しいパスワード（確認）を入力してください。',
      REQUIRED_RECAPTCHA: 'チェックがされていません。',
    },
    completionMessage: {
      SUCCESS_CHANGE_PASSWORD: 'パスワードを変更しました。',
      ERROR_CHANGE_PASSWORD: '仮パスワードの変更に失敗しました。しばらく待ってからやり直してください。',
    },
    TEXT_1: '新規パスワードを入力してください。',
    TEXT_2: '数字・英字大文字・英字小文字を使用して8文字以上で登録してください。',
    INPUT_LABEL: {
      NEW_PASSWORD: '新しいパスワード',
      NEW_PASSWORD_CHECK: '新しいパスワード（確認）',
    },
  },
  changePassword: {
    TITLE: 'パスワード変更',
    TEXT: '数字・英字大文字・英字小文字を使用して8文字以上で登録してください。',
    LABEL_CURRENT_PASS: '現在のパスワード',
    LABEL_NEW_PASS: '新しいパスワード',
    LABEL_CONFIRM_PASS: '新しいパスワード（確認）',
    PLACEHOLDER: '必須項目*',
    BUTTON_SAVE: '保存',
    validationError: {
      REQUIRED: '必須項目が入力されていません。',
      PASSWORD_FORMAT: 'パスワードが正しくありません。数字・英字大文字・英字小文字を使用して8文字以上で登録してください。',
      CONFIRM_ERROR_NEW_PASSWORD: '新しいパスワードの入力内容が異なります。再度新しいパスワードを入力してください。',
      CONFIRM_ERROR_CONFIRM_PASSWORD: '新しいパスワード（確認）の入力内容が異なります。再度新しいパスワード（確認）を入力してください。',
    },
    SUCCESS: 'パスワードを変更しました。',
    ERROR_400: 'パスワードを変更できませんでした。現在のパスワードが正しいか確認して再度実行してください。',
    ERROR_OTHER: 'パスワードの変更に失敗しました。しばらく待ってからやり直してください。',
  },
  sidebar: {
    TITLE: {
      ACCOUNT_MANAGEMENT: 'アカウント管理',
      PROJECT_MANAGEMENT: 'プロジェクト管理',
    },
    MENU: {
      CUSTOMERS: '企業アカウント一覧',
      SIGN_UP_CUSTOMER: '企業アカウント新規作成',
      SUPPLIERS: '凸版アカウント一覧',
      SIGN_UP_SUPPLIER: '凸版アカウント新規作成',
      PROJECTS: 'プロジェクト一覧',
      CREATE_PROJECT: 'プロジェクト新規登録',
      EDIT_ACCOUNT: 'アカウント編集',
      CHANGE_PASSWORD: 'パスワード変更',
      LOGOUT: 'ログアウト',
      MANUAL: '-マニュアル-',
      TOS: '-利用規約-',
    },
    completionMessage: {
      logout: {
        ERROR: 'ログアウトに失敗しました。しばらく待ってからやり直してください。',
      },
    },
  },
  forgotPassword: {
    TITLE: 'パスワードを忘れたとき',
    DESCRIPTION: '登録メールアドレス宛に、検証コードを送信します。',
    ERROR: '送信に失敗しました。',
    BUTTON_SEND: '送信',
    BUTTON_TO_LOGIN: 'ログイン画面へ',
    LABEL_MAIL: 'メールアドレス',
    PLACEHOLDER: '必須項目*',
    validationError: {
      REQUIRED: 'メールアドレスが入力されていません。',
      EMAIL_FORMAT: 'メールアドレスの形式に誤りがあります。',
    },
  },
  resetPassword: {
    TITLE: 'パスワードリセット',
    DESCRIPTION: '届いたメールに記載されている検証コードを入力してください。',
    SUCCESS: 'パスワードを変更しました。',
    ERROR: 'パスワードのリセットに失敗しました。入力内容に誤りがないか確認し、再度実行してください。',
    BUTTON_SEND: '送信',
    LABEL_CODE: '検証コード',
    LABEL_NEW_PASS: '新しいパスワード',
    LABEL_CONFIRM_PASS: '新しいパスワード（確認）',
    PLACEHOLDER: '必須項目*',
    validationError: {
      REQUIRED: '必須項目が入力されていません。',
      PASSWORD_FORMAT: 'パスワードが正しくありません。数字・英字大文字・英字小文字を使用して8文字以上で登録してください。',
      CONFIRM_ERROR_NEW_PASSWORD: '新しいパスワードの入力内容が異なります。再度新しいパスワードを入力してください。',
      CONFIRM_ERROR_CONFIRM_PASSWORD: '新しいパスワード（確認）の入力内容が異なります。再度新しいパスワード（確認）を入力してください。',
    },
  },
  topPage: {
    PAGE_TITLE: '管理画面トップページ',
    LABEL_ANNOUNCEMENTS: 'movring事務局からのお知らせ',
    BUTTON_SAVE: '保存',
    SUCCESS_EDIT_ANNOUNCEMENT: 'お知らせを保存しました。',
    ERROR_EDIT_ANNOUNCEMENT: 'お知らせの保存に失敗しました。',
    ERROR_GET_USER: 'ユーザー情報の取得に失敗しました。しばらく待ってからやり直してください。',
    ERROR_GET_ANNOUNCEMENT: 'お知らせの取得に失敗しました。しばらく待ってからやり直してください。',
  },
});

export default strings;
