import styled from 'styled-components';
import AppButton from 'common/components/atoms/AppButton';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import FileItem from './FileItem';
import ActionDialog from './ActionDialog';

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 75vh;
  overflow: hidden;
`;

const SubmitExplain = styled.div`
  width: 100%;
`;

const FileArea = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const FileSelectWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
`;

const Text = styled.div`
  color: #4B4B4B;
  font-size: 22px;
  font-weight: bold;
`;

const ButtonArea = styled.div`
  margin: 0.5rem 0 1.2rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
`;

const FileSelectButton = styled.button`
  justify-self: start;
  margin-left: 1.2rem;
  color: #545871;
  font-size: 12px;
  background-color: #F0F1F7;
  border: none;
  border-radius: 3px;
  padding: 0.4rem 0.6rem;
  cursor: pointer;
`;

type SubmitDialogProps = {
  dialogOpen: boolean
  handleClose: () => void
  getRootProps: () => DropzoneRootProps
  getInputProps: () => DropzoneInputProps
  handleSaveFile: () => void
  files: File[]
  open: () => void
  isLoading: boolean
}

const SubmitDialog: React.FC<SubmitDialogProps> = (props: SubmitDialogProps) => {
  const {
    dialogOpen, handleClose, getRootProps, getInputProps, handleSaveFile, files, open,
    isLoading,
  } = props;

  return (

    <ActionDialog
      open={dialogOpen}
      onClose={handleClose}
      maxWidth="lg"
      title="ファイルアップロード"
      titleContent={null}
    >
      <DialogContent>
        {files.length === 0 && (
          <FileSelectWrapper>
            <Text style={{ textAlign: 'center' }}>ファイルをドロップ</Text>
            <br />
            <Text style={{ fontSize: 14, textAlign: 'center' }}>1ファイル5GBまでアップロード可能です。</Text>
            <Text style={{ fontSize: 14, textAlign: 'center' }}> 5GBより大きいファイルはファイルを分割してアップロードしてください。</Text>
          </FileSelectWrapper>
        )}
        {files.length > 0 && (
          <SubmitExplain>
            <p style={{ margin: '15px 40px' }}>
              以下のファイルを入稿します。
              <br />
              入稿データ（素材・指示書）が揃っているか確認し、問題なければ「入稿」ボタンをクリックしてください。
              <br />
              入稿すると、設定した通知先へ入稿完了メールを送信してお知らせします。
            </p>
          </SubmitExplain>
        )}
        <FileArea {...getRootProps()}>
          <input {...getInputProps()} />
          {files.length > 0 && (
            files.map((file) => <FileItem key={file.name} fileName={file.name} />)
          )}
        </FileArea>
        <ButtonArea>
          <FileSelectButton type="button" onClick={open}>ファイルを選択</FileSelectButton>
          <AppButton
            value="入稿"
            width="192px"
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick={isLoading ? () => { } : handleSaveFile}
            backgroundColor="var(--submit-button-color)"
          />
        </ButtonArea>
      </DialogContent>
    </ActionDialog>
  );
};

export default SubmitDialog;
